import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { isProd } from '../../utils/env';

const dDApplicationId = '213d380d-0381-46ec-9c03-afcaa42b7510';
const dDClientTokenId = 'pub3cf109166f6fcdba3a04d7d6dc990b5e';
const dDSite = 'datadoghq.com';
const dDService = 'dashboards';

datadogLogs.init({
  clientToken: dDClientTokenId,
  site: dDSite,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: dDService,
  env: isProd() ? 'production' : 'staging',
});

datadogLogs.addLoggerGlobalContext('host', window.location.host);

export const datadogLogger = datadogLogs.logger;

datadogRum.init({
  applicationId: dDApplicationId,
  clientToken: dDClientTokenId,
  site: dDSite,
  service: dDService,
  env: isProd() ? 'production' : 'staging',
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingOrigins: [/bites-api.com/, /api.small-bites.com/, /api.mybites.io/],
});

datadogRum.startSessionReplayRecording();
