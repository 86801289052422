import { Checkbox, Grid, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TShareTemplate, TShareTemplates } from '../../../app/integration.api';
import BitesLogo from '../../../assets/images/bite-logo.png';
import { selectOrg } from '../../../features/org/org.slice';
import { IEntityType } from '../../../types/entity';
import { EIntegrations, TAllowedDMShareIntegrations } from '../../../types/integration';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Textarea from '../../Inputs/Textarea';
import Modal from '../../Modal';
// eslint-disable-next-line import/namespace
import { MultiReminder } from '../../MultiReminder';
import { useDistributionReminderDealy } from '../hooks/useDistributionReminderDelay';

import MessageEmailPreview from './preview/MessageEmailPreview';
import MessagePreviewLink from './preview/MessagePreviewLink';
import ShareTemplateSelection from './ShareTemplateSelection';
import { getIntegrationFormattedTitle, getIntegrationIcon, replaceDistributionTextPlaceholders } from './utils';

interface IProps {
  entity: IEntityType;
  integration: TAllowedDMShareIntegrations;
  isOpen: boolean;
  onClose: () => void;
  sendedUsersOrGroupsText: string;
  shareableTitle: string;
  shareablePath: string;
  onShare: (args: IOnShareWithSMSArgs | IOnShareWithWhatsappArgs) => void;
  templates?: TShareTemplates;
  customMessage?: string;
  shareableCoverUrl?: string;
}

export type IOnShareWithSMSArgs = IOnShareWithDMArgs;
export type IOnShareWithBeekeeperArgs = IOnShareWithDMArgs & { customReplyMessage: string };
export type IOnShareWithWhatsappArgs = IOnShareWithDMArgs & { templateId: number };

export interface IOnShareWithDMArgs {
  message: string;
  templateId?: number;
  sendReminder: boolean;
  sendReminderDateTime?: moment.Moment;
  sendLater: boolean;
  sendLaterDateTime?: moment.Moment;
  integrationType: TAllowedDMShareIntegrations;
  setIsSendButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDays: { [key: string]: boolean };
  occurrenceTimes: number;
}

function ShareWithIntegrationModal({
  entity,
  integration,
  isOpen,
  onClose,
  sendedUsersOrGroupsText,
  shareableTitle,
  shareablePath,
  onShare,
  templates,
  customMessage,
  shareableCoverUrl,
}: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const org = useSelector(selectOrg);
  const [errorSendLater, setErrorSendLater] = useState(null);
  const [errorSendReminder, setErrorSendReminder] = useState(null);
  const [sendReminder, setSendReminder] = useState(false);
  const [sendLater, setSendLater] = useState(false);
  const [selectedDays, setDays] = useState({});
  const [occurrenceTimes, setOccurrenceTimes] = useState(3);
  const [sendLaterDateTime, setSendLaterDateTime] = useState(moment().local().add(1, 'hours'));
  const [sendReminderDateTime, setSendReminderDateTime] = useState(moment().local());
  const [selectedTemplate, setSelectedTemplate] = useState<TShareTemplate>(templates?.[0]);
  const { text: delayText, delay } = useDistributionReminderDealy({ integration });
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const integrationTitle = useMemo(() => getIntegrationFormattedTitle(integration), [integration]);
  const headerIcon = useMemo(() => getIntegrationIcon(integration), [integration]);
  const [editedCustomisable, setEditedCustomisable] = useState<null | string>(customMessage ?? null);

  useEffect(() => {
    setSendReminderDateTime(sendReminderDateTime.add(delay, 'hours'));
  }, [delay, sendReminderDateTime]);

  const selectedTemplateText = useMemo(() => {
    if (editedCustomisable) {
      return editedCustomisable;
    }

    return selectedTemplate?.text ?? selectedTemplate?.content1 ?? selectedTemplate?.example;
  }, [selectedTemplate, editedCustomisable]);

  const selectedTemplateTextPreview = useMemo(() => {
    return replaceDistributionTextPlaceholders({
      title: selectedTemplateText,
      organizationName: org.readableName || org.name,
      biteName: shareableTitle,
      fullName: 'John Doe',
    });
  }, [org.name, org.readableName, selectedTemplateText, shareableTitle]);

  const setSentLaterDataTimeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorSendLater(null);
    const minTime = moment().local();
    const laterTime = moment(e.target.value);
    if (laterTime.isBefore(minTime)) {
      setErrorSendLater('send later time should be greater than current time');
    } else {
      setSendLaterDateTime(laterTime);
    }
  };

  const setSentRemainderDataTimeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minTime = sendLater ? sendLaterDateTime : moment().local();
    const remainderTime = moment(e.target.value);
    if (remainderTime.isBefore(minTime)) {
      setErrorSendReminder('send remainder time should be greater than send later time or current time');
    } else {
      setSendReminderDateTime(remainderTime);
    }
  };

  return (
    <Modal
      title={t('share.modalTitle', {
        entity: entity.toUpperCase(),
        integration: t(integrationTitle),
      })}
      HeaderIcon={headerIcon}
      isOpen={isOpen}
      className={classes.modal}
      onClose={onClose}
      content={
        <div className={classes.content}>
          <div className={classes.contentTitle}>{sendedUsersOrGroupsText}</div>
          <div className={classes.contentBottomContainer}>
            <div className={classes.contentBottom}>
              <div className={classes.shareContainer}>
                <ShareTemplateSelection
                  setTemplate={setSelectedTemplate}
                  template={selectedTemplate}
                  options={templates ?? []}
                />
                {customMessage && (
                  <Textarea
                    placeholder={t('share.en terYourMessage')}
                    className={classes.textarea}
                    value={editedCustomisable}
                    onChange={setEditedCustomisable}
                  />
                )}
                <div className={classes.preview}>
                  {integration !== EIntegrations.Email && (
                    <div className={classes.previewTop}>
                      <div className={classes.logoContainer}>
                        <img src={BitesLogo} className={classes.logo} alt='logo' />
                      </div>
                      <div className={classes.previewTopTextContainer}>
                        <div className={classes.previewTopTitle}>{shareableTitle}</div>
                        <div className={classes.previewTopDetails}>
                          {t('common.poweredBy')}
                          <br />
                          mybiteshares.com
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes.previewBottomText}>
                    {integration !== EIntegrations.Email && selectedTemplateText && selectedTemplateTextPreview}
                    {integration === EIntegrations.Email && selectedTemplate && (
                      <MessageEmailPreview
                        shareableTitle={shareableTitle}
                        organizationName={org.readableName || org.name}
                        shareableCoverUrl={shareableCoverUrl}
                        subject={selectedTemplate.subject}
                        content1={selectedTemplate.content1}
                        content2={selectedTemplate.content2}
                      />
                    )}
                    {(selectedTemplate || customMessage) && integration !== EIntegrations.Email && (
                      <MessagePreviewLink path={shareablePath} selectedText={selectedTemplateText} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.sendLaterContainer}>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography color={sendLater ? 'textSecondary' : 'textPrimary'}>{t('share.sendNow')}</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={sendLater}
                    onChange={() => setSendLater(!sendLater)}
                    name='checkedC'
                    color='primary'
                  />
                </Grid>
                <Grid item>
                  <Typography color={sendLater ? 'textPrimary' : 'textSecondary'}>{t('share.sendLater')}</Typography>
                </Grid>
              </Grid>
              {sendLater && (
                <>
                  <TextField
                    id='datetime-local'
                    label={t('share.sendAt')}
                    type='datetime-local'
                    value={sendLaterDateTime.format('YYYY-MM-DDTHH:mm')}
                    onChange={setSentLaterDataTimeHandler}
                    size='small'
                    inputProps={{ style: { fontSize: '0.9em', height: 25 } }}
                    className={classes.sendLaterDateTimeField}
                    error={errorSendLater}
                    helperText={errorSendLater}
                    fullWidth
                  />
                  <Typography color='textSecondary' className={classes.sendLaterDateTimePreview}>
                    ({sendLaterDateTime.fromNow()})
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.checkboxContainer}>
              <FormControlLabel
                className={classes.checkboxFormControlLabel}
                control={
                  <Checkbox color='primary' onChange={() => setSendReminder(!sendReminder)} checked={sendReminder} />
                }
                label={<Typography className={classes.checkboxLabel}>{delayText}</Typography>}
              />
            </div>
            {sendReminder && (
              <div className={classes.sendLaterContainer}>
                <TextField
                  id='datetime-local'
                  label={t('share.reminderAt')}
                  type='datetime-local'
                  value={sendReminderDateTime.format('YYYY-MM-DDTHH:mm')}
                  onChange={setSentRemainderDataTimeHandler}
                  size='small'
                  inputProps={{ style: { fontSize: '0.9em', height: 25 } }}
                  className={classes.sendLaterDateTimeField}
                  error={errorSendReminder}
                  helperText={errorSendReminder}
                  fullWidth
                />
                <Typography color='textSecondary' className={classes.sendLaterDateTimePreview}>
                  ({sendReminderDateTime.fromNow()})
                </Typography>
                <MultiReminder
                  selectedDays={selectedDays}
                  setDays={setDays}
                  occurrenceTimes={occurrenceTimes}
                  setOccurrenceTimes={setOccurrenceTimes}
                  remainderDate={sendReminderDateTime}
                />
              </div>
            )}
          </div>

          <PrimaryButton
            className={classes.sendButton}
            onClick={() =>
              onShare({
                message: selectedTemplateText ? selectedTemplateText : 'place holder',
                sendReminder,
                sendReminderDateTime,
                sendLater,
                sendLaterDateTime,
                integrationType: integration,
                setIsSendButtonDisabled,
                templateId: selectedTemplate?.id,
                selectedDays,
                occurrenceTimes,
              })
            }
            disabled={isSendButtonDisabled}
          >
            Send
          </PrimaryButton>
        </div>
      }
    />
  );
}

const useStyles = makeStyles({
  modal: {
    width: 360,
  },
  content: {
    paddingBottom: 30,
  },
  contentTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    padding: '19px 0',
    textAlign: 'center',
    borderBottom: 'solid 1px rgba(179, 179, 179, 0.3)',
    color: '#4d4d4d',
  },
  contentBottomContainer: {
    paddingTop: 25,
    paddingBottom: 25,
    display: 'flex',
    flexDirection: 'column',
  },
  contentBottom: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectLanguage: {
    marginInlineEnd: '10px',
  },
  shareContainer: {
    width: 240,
  },
  sendLaterContainer: {
    margin: '20px 30px 0 30px',
  },
  sendLaterDateTimeField: {},
  sendLaterDateTimePreview: {
    fontSize: '0.9em',
  },
  checkboxContainer: {
    margin: '15px 18px 0 18px',
  },
  checkboxFormControlLabel: {
    margin: 0,
  },
  checkboxLabel: {
    fontSize: '0.8rem',
  },
  textarea: {
    marginBottom: 14,
  },
  preview: {
    background: '#fff',
    padding: 7,
    borderRadius: 10,
    marginTop: 10,
  },
  previewTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  logoContainer: {
    flex: '0 0 39px',
    height: 39,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  previewTopTextContainer: {
    backgroundColor: '#e6e6e6',
    padding: 7,
    flex: '0 0 187px',
  },
  previewTopTitle: {
    fontSize: 12,
    marginBottom: 5,
  },
  previewTopDetails: {
    fontSize: 10,
    color: '#808080',
  },
  previewBottomText: {
    fontSize: 12,
    color: '#000',
    wordBreak: 'break-word',
  },
  sendButton: {
    position: 'relative',
    textAlign: 'center',
    margin: '0 auto',
  },
});

export default ShareWithIntegrationModal;
