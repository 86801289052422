import { AxiosResponse } from 'axios';
import React, { useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as BackIcon } from '../../../../../../assets/icons/icons-back.svg';
import { ReactComponent as TwoPeople } from '../../../../../../assets/icons/icons-invite-default.svg';
import Loader from '../../../../../../components/Loader';
import { currentUserSelector } from '../../../../../auth/auth.selectors';
import { selectOrg } from '../../../../../org/org.slice';
import { csvUploadUrl, validateCsv } from '../../../../userManagement.api';
import { EPagesSyncUpload } from '../../SyncUploadModal';
import { ErrorMessageComponent, HelpHelper, iconSize, modalColors, S, StatusBarHelper } from '../../ui';

const iconStyle = { width: iconSize, height: iconSize };
const headerStyle = { width: '100%' };
const contentStyle = { fontWeight: 'bold' };
const controllerStyle = { margin: 'auto' };
const fileInputStyle = { marginLeft: '33%', marginBottom: '15px' };

export interface IValidation {
  setPage: (evt: EPagesSyncUpload) => void;
  setUploadDetails?: ISuccessValidation | any;
  setValidationErrorList?: any;
  validationErrorList?: any;
  uploadDetails?: any;
}

export interface ISuccessValidation {
  added: any[];
  modified: any[];
  deleted: any[];
  url: string;
}

export interface IDetails {
  id: string | number | undefined;
  username: string;
  password: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  phone: string;
  personal_id: string;
  secondary_email: string;
  dataValidationErrors: IFields[];
}

export interface IFields {
  field: string;
  message: string;
  rowIndex: number;
}

interface IValidateResponse {
  changelist: ISuccessValidation;
  success: boolean;
  errorCode: number;
  details: IDetails[];
}

const iconWrapperStyle = { top: '-25px', position: 'relative' };
const uploadButtonStyle = {
  margin: 'auto',
  backgroundColor: modalColors.blueButtonBG,
  color: modalColors.blueButtonColor,
  flex: 1,
};

const UploadCSV = (props: IValidation) => {
  const { t } = useTranslation();
  const { id } = useSelector(selectOrg);
  const currentUser = useSelector(currentUserSelector);
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState<{
    message: string;
    details?: { text: string };
    extraDetails?: { firstName: string; lastName: string; email?: string; phone?: string; employeeId?: string }[];
  }>(null);
  const { setUploadDetails, setValidationErrorList, setPage } = props;
  const { usersPrimaryKey } = useSelector(selectOrg);
  const [file, setFile] = useState(null);

  const handleBackClick = useCallback(() => {
    setPage(EPagesSyncUpload.INSTRUCTION);
  }, [setPage]);

  const handleFileInputChange = useCallback((e) => {
    setFile(e.target.files[0]);
  }, []);

  const handleUploadCSVClick = useCallback(async () => {
    setSpinner(true);
    setError(null);
    if (!file) {
      setError({ message: t('common.didntSelectAFile') });
      setSpinner(false);
      return;
    }

    try {
      const csvUploaUrl = (await csvUploadUrl(id)) as AxiosResponse<{ url: string }>;
      // const csvUploaUrl = await axios.get(`http://localhost:3000/api/organization/${id}/csv_upload_url/`);

      const uploadS3 = await fetch(csvUploaUrl.data.url, {
        method: 'PUT',
        body: file,
      });

      const { data } = (await validateCsv(id, {
        link: uploadS3.url,
        user_id: currentUser.id,
      })) as AxiosResponse<IValidateResponse>;
      // const { data } = await axios.post(
      //   `http://localhost:3000/api/organization/${id}/validate_csv/`,
      //   { link: uploadS3.url, user_id: currentUser.id },
      //   { headers: { 'Content-Type': 'application/json', 'X-API-Key': '02dff1d6617b476dbe55970fdd5f5219' } },
      // );

      const validationObj = {
        added: data.changelist.added.length,
        modified: data.changelist.modified.length,
        deleted: data.changelist.deleted.length,
        admins: data.changelist.deleted.filter((user) => user.isAdmin),
        url: uploadS3.url,
      };

      setUploadDetails(validationObj);
      setPage(EPagesSyncUpload.PREVIEW);

      setSpinner(false);
    } catch (e) {
      const { status, data } = e.response;

      if (status === 400) {
        if (data?.errorCode === 5) {
          setValidationErrorList(data.details);
          setPage(EPagesSyncUpload.ERROR);
        } else {
          setError({ message: data.message, details: data.details, ...(data.user && { extraDetails: data.user }) });
        }
      } else {
        setError({ message: t('common.error'), details: data.details });
      }

      setSpinner(false);
    }
  }, [currentUser.id, file, id, setPage, setUploadDetails, setValidationErrorList, t]);

  return (
    <S.Wrapper style={{ height: `${window.innerHeight - 200}px`, overflow: 'auto', minHeight: 500 }}>
      <S.BackAndHeaderWrapper>
        <S.IconWrapper style={iconWrapperStyle} onClick={handleBackClick}>
          <BackIcon style={iconStyle} />
        </S.IconWrapper>
        <S.TextHeader style={headerStyle}>{t('userManagement.pages.headerText')}</S.TextHeader>
      </S.BackAndHeaderWrapper>
      <S.IconWrapper>
        <TwoPeople style={iconStyle} />
      </S.IconWrapper>
      <S.Content style={contentStyle}>{t('userManagement.pages.previewValidation.almostDone')}</S.Content>
      <S.Content>{t('userManagement.pages.previewValidation.uploadUpdatedFile')}</S.Content>

      {spinner ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <S.Controllers style={controllerStyle}>
          <S.InputFile
            type='file'
            onChange={(e) => {
              handleFileInputChange(e);
            }}
            style={fileInputStyle}
          />

          <S.ModelButton style={uploadButtonStyle} onClick={handleUploadCSVClick}>
            {t('userManagement.pages.previewValidation.uploadFile')}
          </S.ModelButton>
          {error ? <ErrorMessageComponent {...error} pk={usersPrimaryKey} /> : null}
        </S.Controllers>
      )}

      <HelpHelper />
      <StatusBarHelper numOfActiveItems={3} />
    </S.Wrapper>
  );
};

export default memo(UploadCSV);
