import { Grid, Typography, Button, makeStyles, TextField, Chip } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const days = [
  { label: 'Sun', data: { key: 'Sunday', value: 0 } },
  { label: 'Mon', data: { key: 'Monday', value: 1 } },
  { label: 'Tue', data: { key: 'Tuesday', value: 2 } },
  { label: 'Wed', data: { key: 'Wednesday', value: 3 } },
  { label: 'Thu', data: { key: 'Thursday', value: 4 } },
  { label: 'Fri', data: { key: 'Friday', value: 5 } },
  { label: 'Sat', data: { key: 'Saturday', value: 6 } },
];

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '12px',
    paddingTop: '10px',
  },
  button: {
    borderRadius: '50%',
    minWidth: '35px',
    minHeight: '35px',
    margin: theme.spacing(0.5),
    fontSize: '10px',
    padding: 0,
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  unselected: {
    backgroundColor: '#E0E0E0',
    color: '#000',
  },
  inputField: {
    maxWidth: '80px',
    marginRight: theme.spacing(1),
    '& input': {
      textAlign: 'center',
      height: '0.7em',
      fontSize: '1rem',
      padding: theme.spacing(0.5),
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&:focus': {
        outline: 'none',
      },
    },
  },
  text: {
    fontWeight: 500,
    fontSize: '12px',
  },
}));

export const MultiReminder = ({ selectedDays, setDays, occurrenceTimes, setOccurrenceTimes, remainderDate }) => {
  const { t } = useTranslation();
  const [lastReminder, setLastReminder] = useState('');
  const [lastDay, setLastDay] = useState('');
  const classes = useStyles();

  // @ts-ignore
  useEffect(() => {
    if (!_.isEmpty(selectedDays)) {
      calculateLastRemainder();
    }
  }, [selectedDays, occurrenceTimes, calculateLastRemainder]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function calculateLastRemainder() {
    const date: Moment = moment(remainderDate);
    let reminderCount = 0;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let lastReminder: string | null = null;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let lastDay: string | null = null;

    while (reminderCount < occurrenceTimes) {
      date.add(1, 'day'); // Move to the next day
      if (_.has(selectedDays, date.format('dddd'))) {
        lastReminder = date.format('DD/MM/YYYY');
        lastDay = date.format('dddd');
        reminderCount++;
      }
    }
    setLastReminder(lastReminder);
    setLastDay(lastDay);
  }

  const handleDayClick = useCallback(
    (data: { key: string; value: number }) => {
      setDays((prev) => {
        if (data.key in prev) {
          const newDays = { ...prev };
          delete newDays[data.key];
          return newDays;
        }
        return { ...prev, [data.key]: data.value };
      });
    },
    [setDays],
  );

  const handleOccurrenceTimesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Math.max(1, Math.min(15, Number(e.target.value)));
      setOccurrenceTimes(value);
    },
    [setOccurrenceTimes],
  );

  const isDaySelected = useCallback((key: string) => key in selectedDays, [selectedDays]);

  return (
    <>
      <Typography variant='h6' className={classes.title}>
        {t('share.multiRemainder.title')}
      </Typography>
      <Grid container style={{ paddingTop: '10px', flexWrap: 'nowrap' }}>
        {days.map((day) => (
          <Button
            key={day.data.key}
            onClick={() => handleDayClick(day.data)}
            className={`${classes.button} ${isDaySelected(day.data.key) ? classes.selected : classes.unselected}`}
          >
            {day.label}
          </Button>
        ))}
      </Grid>
      {!_.isEmpty(selectedDays) && (
        <>
          <Grid container spacing={1} style={{ paddingTop: '10px' }}>
            <Grid item>
              <Typography className={classes.text}>{t('share.multiRemainder.ends')}</Typography>
            </Grid>
            <Grid item>
              <TextField
                type='number'
                value={occurrenceTimes}
                onChange={handleOccurrenceTimesChange}
                inputProps={{
                  min: 1,
                  max: 15,
                }}
                className={classes.inputField}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.text}>{t('share.multiRemainder.occurrence')}</Typography>
            </Grid>
          </Grid>
          {lastReminder && (
            <Chip
              style={{ marginTop: '10px' }}
              variant='outlined'
              color='secondary'
              icon={<AccessAlarmIcon />}
              label={`Last Reminder: ${lastDay} - ${lastReminder}`}
            />
          )}
        </>
      )}
    </>
  );
};
