export interface IOrgData {
  title: string;
  options: IOrgDataOption[];
  // WARN: If there's a missing field (e.g. data3 is blank but data4 is not),
  //       some old code relying on a list of datas might break
  //       The fields below were added as a hack so consumers can obtain the original data
  //       index/fieldname after being put in a list by the formatDatas() util method
  // SEE: utils/utils.tsx#formatDatas
  dataFieldName: `data${number}`;
  dataIdx: number;
}

export interface IOrgDataOption {
  id: number;
  name: string;
}

export type IOrgDatasStrings = string[];

export interface IOrg {
  logoUrl: string;
  name: string;
  id: number;
  datas: IOrgData[];
}

export enum EOrgUsersPrimaryKey {
  Email = 'email',
  Phone = 'phone',
  EmployeeId = 'employee_id',
}

export const mapOrgPrimaryKey = {
  [EOrgUsersPrimaryKey.Email]: 'email',
  [EOrgUsersPrimaryKey.Phone]: 'phone',
  [EOrgUsersPrimaryKey.EmployeeId]: 'employeeId',
};
